// ** Auth Endpoints
const url = process.env.REACT_APP_BASENAME ? process.env.REACT_APP_BASENAME : 'http://64.227.176.65/api/v1'
export default {
  loginEndpoint: '/jwt/login',
  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',
  // loginNewEntPoint: "http://archer-api.lusites.xyz:3000/v1/auth/login",
  loginNewEntPoint: `${url}/login`,
  resetPasswordEntPoint: `${url}/reset-password`,
  forgotPasswordEntPoint: `${url}/forgot-password`,
  ulipVehicleDetailsPostEndPoint: `${url}/vehicle-details`,
  ulipLoginPostEndPoint: `${url}/ulip-login`,
  addDriver: `${url}/driver-store`,
  // getDriver: `${url}/driver-index`,
  getDriver: `${url}/getDriverList`,
  getVehicle: `${url}/vehicle-index`,
  // driverDelete: `${url}/driver-destroy`,
  driverDelete: `${url}/driverDelete`,
  vehicleDelete: `${url}/vehicle-destroy`,
  getParticularDriver: `${url}/driver-show`,
  updateParticularDriver: `${url}/driver-update`,
  dashboardData: `${url}/dashboard`,
  addSubAdminEndPoint: `${url}/store`,
  branchNamenEndPoint: `${url}/branch-name`,
  updatebranchNamenEndPoint: `${url}/branch-update`,
  deletebranchNamenEndPoint: `${url}/branch-destroy`,
  getBranchNameEndPoint: `${url}/getBranch-name`,
  getSubAdminEndPoint: `${url}/sub-admin`,
  vehicleSaveDetailsEndPoint: `${url}/vehicle-saveDetails`,
  ebillSendDetailsEndPoint: `${url}/ebill-sendDetails`,
  ebillSaveDetailsEndPoint: `${url}/ebill-saveDetails`,
  getEbillIndexEndPoint: `${url}/ebill-index`,
  vehicleRefreshDataEndPoint: `${url}/refresh`,
  echallanListingEndPoint: `${url}/echallan-index`,
  getDriverDetailsEndPoint: `${url}/getDriver`,
  saveDriverDetailsEndPoint: `${url}/saveDriver`,

  updateSubAdminEndPoint: `${url}/subAdmin-update`,
  deleteSubAdminEndPoint: `${url}/subAdmin-destroy`,
  uploadConsignmentDetails: `${url}/image-store`,
  echallanDetails: `${url}/echallan-details`,
  fastagDetails: `${url}/fastag-details`,
  podsLists: `${url}/pods-list`,
  fastagLists: `${url}/fastag-index`,
  fastagListsShow: `${url}/fastag-show`, //id
  fastagDelete: `${url}/fastag-destroy`, //id


  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
}
